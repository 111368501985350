// Import Fuse core library
//@import "@fuse/scss/core";

// Import app.theme.scss
//@import "app/app.theme";

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Quicksand:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'CCClobberinTime-Smooth';
    src: url('assets/fonts/CCClobberinTime-Smooth.woff2') format('woff2'),
        url('assets/fonts/CCClobberinTime-Smooth.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GoodDogNewW00';
    src: url('assets/fonts/GoodDogNewW00-Regular.woff2') format('woff2'),
        url('assets/fonts/GoodDogNewW00-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



h2{
    text-transform: uppercase;
    font-family: "Quicksand";
}
.filepond--file {
    background-color: var(--fuse-accent-500)!important;
}

.filepond--drip-blob {
    background-color: var(--fuse-accent-200)!important;
}

.filepond--file-action-button.filepond--action-remove-item {
    background-color:  var(--fuse-primary-500)!important;
    color: var(--fuse-on-primary-500)!important;
}
.filepond--file-action-button.filepond--action-remove-item:hover,
.filepond--file-action-button.filepond--action-remove-item:focus {
    box-shadow: 0 0 0 0.125em var(--fuse-on-primary-500)!important;
}
.title-font{
    font-family: "Quicksand";
}
// Global custom design
.custom-dialog-container .mat-dialog-container {
    padding: 0;
    overflow: hidden;
    position: relative;
}

// ==== NAV BAR ==== //

// ==== HEADER ==== //
.header {
    height: 200px !important;
    min-height: 200px !important;
    max-height: 200px !important;
    background-size: cover;
    z-index: -10;

    /*@include media-breakpoint('xs') {
        height: 240px;
        padding: 16px;
    }*/@at-root
    .hero-icon {
        position: absolute;
        top: -64px;
        left: 0px;
        opacity: 0.04;
        font-size: 512px !important;
        width: 512px !important;
        height: 512px !important;
        z-index: -5;
    }

    h1 {
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.01em;
        text-align: center;
        margin-top: 0;
        margin-bottom: 16px;

        /*@include media-breakpoint('xs') {
            font-size: 24px;
        }*/
    }

    h3 {
        position: absolute;
        left: 24px;
        bottom: 8px;

        max-width: 600px;
        text-align: center;
        font-weight: 300;
        letter-spacing: 0.03em;
        margin: auto;

        /*@include media-breakpoint('xs') {
            font-size: 14px;
        }*/
    }

}

// ==== PAGE-CONTENT ==== // 
.page-content {

    width: 100%;
    height: 100%;
    padding: 18px;
    background-color: #E6E6E6;

    .card {

        border: none !important;
        border-radius: 5px !important;
        padding: 16px;
        margin-bottom: 18px;
        background-color: white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        .card-title {
            font-size: 1.3em;
            font-weight: bold;
        }

        // Custom card content with id
        // .card-content { }

    }

    .center {
        width: 100%;
        display: flex;
        justify-content: center;
    }

}


/* MAT STEPPER */
.mat-step-header {
    background-color: rgb(236, 236, 236);
}

body.theme-it .mat-step-header.cdk-keyboard-focused, body.theme-it .mat-step-header.cdk-program-focused, body.theme-it .mat-step-header:hover {
	background-color: rgb(236, 236, 236);
}

